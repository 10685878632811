<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <div class="filter-card">
          <div class="filter-field">
            <label>訊息數量</label>
            <h3 style="color: #fff; width: 120px">{{ messageQuantity.total_Count }}</h3>
          </div>
          <div class="filter-field">
            <label>未讀</label>
            <h3 style="color: #fff; width: 120px">{{ messageQuantity.unread_Count }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="table-wrap">
        <el-table :data="messageList" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column align="center" width="90px">
            <template>
              <img width="40" src="~@/assets/img/index_menu_m4.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="主旨" prop="msG_TITLE" />
          <el-table-column label="日期" prop="uP_DATE" />
          <el-table-column label="狀態">
            <template slot-scope="scope">
              <span v-if="scope.row.read">已讀</span>
              <span v-else>未讀</span>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#messageModal"
                @click="message = scope.row"
              >
                檢視訊息
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="count" @current-change="changePage" />
      </div>
    </div>

    <!-- 檢視訊息 -->
    <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>{{ message.uP_DATE }}</div>
            <h5>您已成功下單</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="message-content" style="line-height:200%; padding:20px;">
              <pre v-html="message.msG_BODY"></pre>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="updateMessageStatus"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import { apiGetNewsQuantity, apiGetNews, apiUpdateMessageStatus } from "@/apis/message";

export default {
  name: "message",
  data() {
    return {
      // 客戶資訊
      customerInfo: {},

      // 訊息數量
      messageQuantity: {},
      // 訊息總筆數
      count: 0,
      // 訊息列表
      messageList: [],

      // 訊息資訊
      message: {},

      // 當前分頁
      page: 0
    };
  },
  methods: {
    // 更新訊息狀態為已讀
    updateMessageStatus() {
      if (this.message.read === "true") {
        return;
      } else {
        apiUpdateMessageStatus([
          {
            messageID: this.message.msG_ID
          }
        ]).then(() => {
          this.message = {};
          this.getNews();
          this.getNewsQuantity();
        });
      }
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getNews();
    },

    // 取得訊息列表
    getNews() {
      this.updateLoading(true);
      apiGetNews({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.count = response.data.count;
            this.messageList = response.data.datas;
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得訊息數量
    getNewsQuantity() {
      this.updateLoading(true);
      apiGetNewsQuantity({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        pageindex: "0"
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.messageQuantity = response.data;
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
    this.$axios.all([this.getNews(), this.getNewsQuantity()]);
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
