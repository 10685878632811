import req from './https';

// 取得訊息數量
export const apiGetNewsQuantity  = (params) => req('post', '/News/postNewsCountByCustNox', params);

// 取得訊息列表
export const apiGetNews = (params) => req('post', '/NewsWeb/postNewsListByCustNox', params);

// 更新訊息狀態為已讀
export const apiUpdateMessageStatus = (params) => req('post', '/Message/updateMessageStatus', params);
